<script>
import Gen from '../helper/Gen';

export default {
  props:{
    value: [String, Number],
    url:String,
    isBO:Boolean,
    placeholder:String
  },
  data(){
    return {
      MrDatas:[],
      datas:"",
      onEdit:false,
    }
  },
  computed:{
  },
  async mounted(){
    if(this.value) {
      this.$emit("input", this.value)
      this.$set(this, 'datas', this.value)
      $('.institusi_choice').hide()
    }
    $(this.$el).val(this.value).on("keyup change",(e)=>{
      this.$emit("input", e.target.value)
    })
  },
  methods:{
    getApi(){
      if(!this.url) return
      this.onEdit=true
      Gen.apirest(this.url, {}, (err, resp)=>{
        if (err) console.log(err)
        if(!resp.length) this.onEdit=false
        this.MrDatas = resp
      })
    },
    institutionName(v) {
      this.onEdit=false
      this.$emit("input", v)
			setTimeout(() => {
				$('.institusi_choice').hide()
			}, 300)
		},
  },
  watch:{
    value(v){
      if(!v) return $('.institusi_choice').hide()
      this.getApi()
      this.$emit("input", v)
      this.$set(this, 'datas', v)
      $('.institusi_choice').show()
    },
  }
}
</script>

<style lang="scss" scoped>
.suggest-container{
  position: relative;
}
</style>

<template>
  <div class="form-group">
    <input type="text" class="form-control" v-model="datas" :placeholder="placeholder ? placeholder : ''">
    <ul v-if="onEdit" class="institusi_choice" style="display:none">
      <li v-for="(v,k) in MrDatas" :key="k" @click='institutionName(v.value)'> {{v.value}}</li>
    </ul>
    <!-- <ul v- class="institusi_choice m-t-5" style="display:none">
      <li v-for="(v,k) in MrDatas" :key="k" @click='institutionName(v.value)'> {{v.value}}</li>
    </ul> -->
  </div>
</template>